import bcrypt from "bcryptjs";

export const Algorithms = Object.freeze({
  BCRYPT_afI: "2a$10$afIkWYmksUkXjv/I/HzGlu",
});

export async function hash_password(password, algo) {
  switch (algo) {
    case Algorithms.BCRYPT_afI:
    default:
      return hash_password_bcrypt_afi(password);
  }
}

export async function hash_password_bcrypt_afi(password) {
  return bcrypt.hash(password, "$2a$10$afIkWYmksUkXjv/I/HzGlu");
}
